<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-background="rgba(0,0,0,0.8)"
  ></div>
</template>

<script>
export default {
  name: 'LayoutLoading',
  props: {},
  data() {
    return {
      loading: true,
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
