export default {
  namespaced: true,
  state: {
    currentMatch: undefined,
    completedInterchanges: [],
  },
  mutations: {
    CURRENT_MATCH(state, update) {
      state.currentMatch = update;
    },
    UPDATE_INTERCHANGES(state, update) {
      state.completedInterchanges = update;
    },
    UPDATE_INTERCHANGES_FROM_FEED(state, update) {
      if (update.isHomeTeam) {
        const awayteam = state.completedInterchanges.filter((interchange) => {
          return !interchange.isHomeTeam;
        });
        const hometeam = update.interchanges;
        const interchanges = [].concat(hometeam).concat(awayteam);
        state.completedInterchanges = interchanges;
      } else {
        const hometeam = state.completedInterchanges.filter((interchange) => {
          return interchange.isHomeTeam;
        });
        const awayteam = update.interchanges;
        const interchanges = [].concat(hometeam).concat(awayteam);
        state.completedInterchanges = interchanges;
      }
    },
  },
};
