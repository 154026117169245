<template>
  <div class="login">
    <div class="login-internal">
      <img class="logo" src="@/assets/img/logo/nrl-green.svg" alt="nrl" />
      <p class="title">
        NRL <strong>{{ variant }}</strong>
      </p>
      <input type="text" placeholder="Username" v-model="username" />
      <input type="password" placeholder="Password" v-model="password" />
      <button @click="login">Login</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      username: '',
      password: '',
    };
  },
  computed: {
    variant() {
      return process.env.VUE_APP_PROJECT || 'sideline';
    },
  },
  methods: {
    login() {
      if (!this.username || !this.password) {
        this.$notify({
          type: 'warning',
          title: 'Warning',
          message: this.$createElement('i', 'Please complete login form'),
        });
        return;
      }
      const encodedString = btoa(`${this.username}:${this.password}`);
      this.$noauth
        .post(
          '/nrl/api/v1/elite/auth/login',
          {},
          {
            headers: {
              Authorization: `Basic ${encodedString}`,
            },
          },
        )
        .then(response => {
          if (response) {
            this.$store.commit('user/LOGIN', response.data);
            const userType = this.$store.getters['user/userType'];
            if (userType >= 2 && userType !== 5) {
              this.$router.push({ name: 'matches' });
            } else if (userType === 1) {
              this.$router.push({ name: 'teamlist' });
            } else {
              this.$notify.error({
                title: 'Error',
                message: 'Not Authorised',
              });
            }
          }
        })
        .catch(e => {
          this.$notify.error({
            title: 'Error',
            message:
              e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : 'There has been an error',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$limit: 230px;

.login {
  height: 100%;
  display: flex;

  .login-internal {
    margin: auto;
    width: $limit;
    height: 500px;
    /*border: 1px solid black;*/

    > * {
      margin-top: 20px;
    }

    .logo {
      width: 120px;
    }
    .title {
      text-transform: uppercase;
      font-size: 1.5em;
    }

    button {
      margin-top: 40px;
    }
  }
}
</style>
