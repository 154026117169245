import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login";
import NrlAccLogin from "@/views/auth/NrlAccLogin";
import PageNotFound from "../components/PageNotFound";
import Oauth from "@/views/auth/Oauth";
import store from "../store";
import noauth from "../axios/noauth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: NrlAccLogin,
  },
  {
    path: "/oauth",
    name: "oauth",
    component: Oauth,
  },
  {
    path: "/landing",
    name: "landing",
    component: () => import("@/views/Landing"),
    meta: {
      header: "Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/matches",
    name: "matches",
    component: () => import("@/views/MatchList"),
    meta: {
      header: "select fixture",
      requiresAuth: true,
    },
  },
  {
    path: "/teamlist",
    name: "teamlist",
    component: () => import("@/views/TeamList"),
    meta: {
      header: "submit teamlist",
      requiresAuth: true,
    },
  },
  {
    path: "/matches/:id",
    name: "matchevents",
    component: () => import("@/views/MatchEvents"),
    meta: {
      header: "Match Events",
      requiresAuth: true,
    },
  },
  {
    path: "*",
    name: "404",
    meta: {
      displayName: "Page Not Found",
    },
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const now = Date.now();
    const expiry = store.getters["user/expiry"];
    const userid = store.getters["user/userid"];
    const isAuthenticated = store.getters["user/isAuthenticated"];
    const refreshToken = store.getters["user/refreshToken"];
    const verified = store.getters["user/isVerified"];
    const roles = store.getters["user/roles"];
    const roleType = store.getters["user/activeRole"].type;

    if (isAuthenticated && expiry - 300 > now) {
      if (!verified) {
        console.log("profile not verified");
        store.commit("user/logout");
        router.push("/");
      } else {
        // Account Verified
        console.log("account verified");
        next();
      }
    } else if (expiry - 300 <= now && refreshToken && userid) {
      // Expired Token
      // Refresh Token before going to page
      noauth
        .post("/nrl/api/v1/elite/authorize", {
          refresh_token: refreshToken,
          user_id: userid,
        })
        .then((response) => {
          // Refresh Successful - Login User
          store.commit("user/login", response.data);
          next();
        })
        .catch(() => {
          // Failed Refresh - Logout User
          store.commit("user/setRedirectUriPath", `${to.fullPath}`);
          store.commit("user/logout");
          router.go("/");
        });
    } else {
      // No Data - Redirect to Login
      store.commit("user/setRedirectUriPath", `${to.fullPath}`);
      store.commit("user/logout");
      next("/");
    }
  } else {
    next();
  }
});

export default router;
