import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import user from './user';
import match from './match';

Vue.use(Vuex);

const persistLocal = new VuexPersist({
  key: 'nrl-elite-user',
  storage: window.localStorage,
  modules: ['user'],
});

const root = {
  state: {
    loading: false,
    apiUrl: process.env.VUE_APP_API_URL,
    s3Url: process.env.VUE_APP_S3,
  },
  mutations: {
    LOADING(state, update) {
      state.loading = update;
    },
  },
  actions: {},
};

export default new Vuex.Store({
  modules: {
    root,
    user,
    match,
  },
  plugins: [persistLocal.plugin],
});
