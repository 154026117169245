import axios from "axios";
import store from "../store";

// Open Auth Requests
export default axios.create({
  baseURL: store.state.root.apiUrl,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*"
  },
});
