<template>
  <div class="tw-flex tw-justify-center tw-mt-24">
    <div
      class="tw-flex tw-flex-col tw-items-center tw-w-64 tw-p-10 tw-bg-white tw-rounded tw-shadow"
    >
      <img src="~@/assets/img/logo/shield_black.svg" />
      <h3 class="tw-pt-10 tw-text-lg tw-font-bold">Page Not Found</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.notfound-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  .notfound-card {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 20%;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
  }
  @media only screen and (max-width: $sm) {
    .notfound-card {
      width: 80%;
    }
  }
}
</style>
