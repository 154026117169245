<template>
  <el-container v-loading.fullscreen.lock="loading"> </el-container>
</template>

<script>
export default {
  name: "Oauth",
  created() {
    if (!this.$route.query.code) {
      if (this.$store.getters["user/isAuthenticated"]) {
        const roles = this.$store.getters["user/roles"];
        const roleType = this.$store.getters["user/activeRole"].type;
        if (
          roles.length &&
          roles.filter((r) => r.type === 101 || r.type === 51).length === 1
        ) {
          if (roleType === 51)
            this.$store.commit("user/setRedirectUriPath", "/matches");
          if (roleType === 101)
            this.$store.commit("user/setRedirectUriPath", "/teamlist");
        }
        const redirect = this.$store.getters["user/redirectUriPath"];
        this.$router
          .push(`${redirect}`)
          .catch(() => console.log("--- redirected ---"));
      } else {
        this.$router.push("/");
      }
    } else {
      this.$noauth
        .post(
          `/nrl/api/v1/elite/login?code=${this.$route.query.code}&elite=sideline`,
          {},
        )
        .then((response) => {
          this.$store.commit("user/login", response.data);
          const roles = this.$store.getters["user/roles"];
          const roleType = this.$store.getters["user/activeRole"].type;
          if (
            roles.length &&
            roles.filter((r) => r.type === 101 || r.type === 51).length === 1
          ) {
            if (roleType === 51)
              this.$store.commit("user/setRedirectUriPath", "/matches");
            if (roleType === 101)
              this.$store.commit("user/setRedirectUriPath", "/teamlist");
          }
          const redirect = this.$store.getters["user/redirectUriPath"];
          this.$router
            .push(`${redirect}`)
            .catch(() => console.log("--- redirected after oauth---"));
        })
        .catch((e) => {
          this.$router.push("/");
        });
    }
  },
  data() {
    return {
      loading: true,
    };
  },
};
</script>

<style scoped lang="scss"></style>
