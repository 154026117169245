import jwtDecode from "jwt-decode";
// import * as Sentry from "@sentry/browser";

export default {
  namespaced: true,
  state: {
    authenticated: false,
    tokens: {},
    profile: {},
    redirectUriPath: "/landing",
    activeRole: false,
  },
  mutations: {
    login(state, tokens) {
      const updateuser = jwtDecode(tokens.id_token);
      state.profile = updateuser;
      state.tokens = tokens;
      state.authenticated = true;

      if (updateuser.roles && updateuser.roles.length > 0) {
        // check if current active role is available
        const filteredByCurrent = updateuser.roles.filter(
          (role) => role._id === state.activeRole._id,
        );
        const filteredByEliteRoles = updateuser.roles.filter(
          (role) => role.type == 101 || role.type === 51,
        );
        if (filteredByCurrent.length > 0) {
          state.activeRole = filteredByCurrent[0];
        } else if (filteredByEliteRoles.length > 0) {
          state.activeRole = filteredByEliteRoles[0];
        } else {
          state.activeRole = false;
        }
      }

      // state.activeRole =
      //   updateuser.roles && updateuser.roles.length > 0 ? updateuser.roles[0] : false;
      // TODO: is sentry needed for user roles?
      // Sentry.setUser({
      //   nrl_id: state.profile ? state.profile._id : "",
      //   nrl_role: state.activeRole || "",
      // });
    },
    logout(state) {
      state.authenticated = false;
      state.tokens = {};
      state.profile = {};
      state.activeRole = false;
    },
    switchRole(state, role) {
      state.activeRole = role;
      // Sentry.setUser({
      //   nrl_id: state.profile ? state.profile._id : "",
      //   nrl_role: state.activeRole || ""
      // });
    },
    setRedirectUriPath(state, update) {
      state.redirectUriPath = update;
    },
    LOGIN(state, tokens) {
      const updateuser = jwtDecode(tokens.id_token);
      state.profile = updateuser;
      state.tokens = tokens;
      state.authenticated = true;
    },
    LOGOUT(state) {
      state.authenticated = false;
      state.tokens = {};
      state.profile = {};
    },
    UPDATE(state, update) {
      // primarily used for updating user status of `verified`
      state.profile = {
        ...state.profile,
        ...update,
      };
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.authenticated;
    },
    redirectUriPath(state) {
      return state.redirectUriPath;
    },
    userid(state) {
      return state.profile._id;
    },
    expiry(state) {
      return state.tokens.expires_in;
    },
    accessToken(state) {
      return state.tokens.access_token;
    },
    refreshToken(state) {
      return state.tokens.refresh_token;
    },
    userName(state) {
      return `${state.profile.firstName}`;
    },
    userType(state) {
      return state.activeRole.type;
    },
    uploadHeaders(state) {
      const { profile, tokens } = state;
      const authheader = `${profile._id}:${tokens.access_token}`;
      const encodedString = btoa(authheader);
      const Authorization = `Bearer ${encodedString}`;
      return { Authorization };
    },
    userProfile(state) {
      return state.profile;
    },
    activeRole(state) {
      return state.activeRole;
    },
    roles(state) {
      return state.profile.roles;
    },
    isVerified(state) {
      return state.profile.verified;
    },
  },
};
